.shop-now {
    background-color: #f0e4d4;
}
.shop-now-title img {
    width: 100%;
    height: 200px;
}
.shop-now-title h2 {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Manrope', sans-serif;
    font-family: 'Noto Serif', serif;
    color: white;
    font-weight: 700;
    font-size: 42px;
}
.shop-now-menu {
    background-color: #f0e4d4;
    margin-bottom: 40px;
}
.shop-now-menu {
    display: flex;
    justify-content: center;
}
.shop-now-menu li {
    list-style: none;
    flex-direction: row;
    padding: 20px 50px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}
.shop-now-menu li:hover {
    color: #e86c4c;
}
.shop-now-item {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.item-shop-img {
    background-image: url('https://cdn.shopify.com/s/files/1/2592/9046/products/1_1620x.png?v=1643821747');
      height: 400px;
      width :300px;
      background-size: cover; 
}
.item-shop-img:hover {
    background-image: url('https://cdn.shopify.com/s/files/1/2592/9046/products/2_1620x.png?v=1654701805');
    width: 300px;
    height: 400px;
    background-size: cover; 
}
.item-shop-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}