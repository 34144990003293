/*START NAVIGATION*/
.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
    margin-bottom: 20px;
}
.kategori {
    display: flex;
}
.kategori li {
    list-style: none;
    padding-left: 20px;
    padding-right: 25px;
    padding-top: 25px;
}
.kategori1 li {
    list-style: none;
    padding-left: 20px;
    padding-right: 25px;
    padding-top: 25px;
}

.dropdown {
    
    overflow: hidden;
    display: inline-block;
  }
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 100%;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
.dropdown-content-list {
    display: flex;
    clear: both;
    border: solid 1px;
    flex-direction: row;
}
.content {
    float: left;
    width: 100%;
    padding: 10px;
    background-color: #ccc;
    height: 250px;
.content a {
    float: none;
    color: black;
    padding: 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}
}
.dropdown:hover .dropdown-content {
    display: block;
}
.logo img{
    width: 150px;
}
/*END NAVIGATION*/

/*LANDING*/
.landing {
    position: relative;
}
.landing img{
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.landing-judul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Manrope', sans-serif;
    font-family: 'Noto Serif', serif;
    color: white;
    font-weight: 700;
    font-size: 62px;
}
.landing-judul button {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 60px;
    font-family: 'Roboto', sans-serif;
    padding: 20px 40px;
    background-color: white;
    border-radius: 40px;
    border: none;
    cursor: pointer;
}
.landing-judul button:hover {
    transform: translateX(-0.01px);
    transition: transform .4s;
}
.shoes-example {
    margin-top: 30px;
    margin-left: 100px;
    margin-right: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.shoes-example-content {
    display: flex;
    flex-direction: column;
}
.shoes-example-content img {
    width: 400px;
    height: 500px;
}
.shoes-example-content img:hover{
    transform: scale(1.02);
    transition: transform .4s;
}
.shoes-example-content button {
    background-color: black;
    color: white;
    border-radius: 40px;
    border: none;
    padding: 15px 40px;
    font-family:'Roboto', sans-serif;
    margin-top: 25px;
    margin-left: 30%;
    margin-right: auto;
    float: center;
}
.shoes-title {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-left: 50px;
    margin-right: 50px;
}
.shoes-title-1 {
    text-align: center;
    font-size: 42px;
    font-weight: bold;
    font-family: 'Manrope', sans-serif;
    font-family: 'Noto Serif', serif;
    margin: 30px 0px;
}
.shoes-group {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
}
.shoes-content {
    display: flex;
    flex-direction: column; 
}
.shoes-content-image img {
    width: 300px;
    height: 400px;
}
.shoes-content-image img:hover {
    transform: scale(1.02);
    transition: transform .4s;
    width: 300px;
    height: 400px;
}
.shoes-content-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.shoes-content-title p{
    font-size: 12px;
}
.shoes-review {
    margin-top: 40px;
    width: 100%;
    background-color:#f8e4d4 ;
    height: 400px;
}
.shoes-review-img img {
    width: 400px;
    height: 400px;
    float: left;
   
}
.shoes-review-title {
    padding-top: 30px;

 }

.shoes-review-title p {
    text-align: center;
}
.shoes-review-title-0 {
    font-size: 42px;
    font-family: 'Manrope', sans-serif;
    font-family: 'Noto Serif', serif;
}
.shoes-review-title-1 {
    font-family:'Roboto', sans-serif;
    font-weight: 100;
}
.shoes-review-title-2 {
    padding-top: 80px;
} 
.review-brand {
    background-color: #7c9fba;
    margin-top: 40px;
}
.review-brand p {
    text-align: center;
    padding-top: 90px;
    font-size: 24px;
    font-family:'Roboto', sans-serif;
    font-weight: 100;
    color: white;
}
.review-brand img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
    padding-bottom: 90px;
}
.shoes-shop {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}
.shoes-shop-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 40px;
}
.shoes-shop-shop {
display: flex;
flex-direction: column;
}
.shoes-shop-shop img {
    width: 600px;
    height: 600px;
}
.shoes-shop-shop img:hover {
    transform: scale(1.02);
    transition: transform .4s;
}
.shoes-shop-shop button {
    background-color: black;
    color: white;
    border-radius: 40px;
    border: none;
    padding: 15px 40px;
    font-family:'Roboto', sans-serif;
    margin-top: 25px;
    margin-left: 30%;
    margin-right: auto;
    float: center;
}
.shoes-shop-1 {
    margin-bottom:40px ;
}
.landing-judul-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Manrope', sans-serif;
    font-family: 'Noto Serif', serif;
    color: white;
    font-weight: 700;
    font-size: 42px;
}
.landing-judul-1 button {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 60px;
    font-family: 'Roboto', sans-serif;
    padding: 20px 40px;
    background-color: black;
    color: white;
    border-radius: 40px;
    border: none;
    cursor: pointer;
}
.shoes-insta {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    background-color: #f8e4d4;
    margin-bottom: 40px;
}
.shoes-insta-title p{
    text-align: center;
    font-family: 'Roboto', sans-serif;
}
.shoes-insta-title-1 {
    text-align: center;
    font-size: 32px;
    font-family: 'Manrope', sans-serif;
    font-family: 'Noto Serif', serif;
    font-weight: 400;
}
.shoes-insta-img {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.shoes-insta-content img {
    width: 400px;
    height: 600px;
}

/*END LANDING*/

/*FOOTER*/
.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #e86c4c;
    height:400px;
}
.footer-content {
    padding: 40px;
}
.footer-content-head li{
    list-style: none;
    color: white;
    font-size: 16px;
}
.footer-content li {
    list-style: none;
    color: white;
    font-size: 12px;
    line-height: 30px;
}