.signin {
    background-color:#f8e4d4 ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 40px;
}
.signin h1 {
    text-align: center;
    padding-top: 70px;
    font-size: 56px;
    font-family: 'Manrope', sans-serif;
    font-family: 'Noto Serif', serif;
}
.signin h3 {
    text-align: center;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}
.signin input {
    margin-left: auto;
    margin-right: auto;
    padding: 15px 60px;
    text-align: center;
    border: none;
    margin-top: 30px;
    border-radius: 15px;
}
.signin button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-family: 'Roboto', sans-serif;
    padding: 15px 50px;
    background-color: black;
    border-radius: 40px;
    color: white;
    border: none;
    cursor: pointer;
}
.text {
    margin-top: 40px;
}
.signin p {
    text-align: center;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    line-height: 0.7;
}
.signin p span {
    text-decoration-line: underline;
}